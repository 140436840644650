import React from 'react';
import { withFormik } from 'formik';
import * as yup from 'yup';
import 'twin.macro';

import InputGroup from '../elements/InputGroup';
import TextareaGroup from '../elements/TextareaGroup';

import { Button } from '../atoms';

const ContactForm = ({
  values,
  touched,
  errors,
  handleChange,
  isSubmitting,
  handleBlur,
  handleSubmit,
}) => (
  <div>
    <form onSubmit={handleSubmit}>
      <InputGroup
        type="text"
        placeholder="Full Name"
        name="name"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.name && touched.name ? errors.name : undefined}
      />
      <div tw="flex flex-wrap -mx-3">
        <div tw="w-full md:w-1/2 px-3">
          <InputGroup
            type="number"
            placeholder="Mobile Number"
            name="telephone"
            value={values.telephone}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.telephone && touched.telephone
                ? errors.telephone
                : undefined
            }
          />
        </div>
        <div tw="w-full md:w-1/2 px-3">
          <InputGroup
            type="email"
            placeholder="Email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email && touched.email ? errors.email : undefined}
          />
        </div>
      </div>
      <TextareaGroup
        rows="4"
        placeholder="Message"
        name="message"
        value={values.message}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.message && touched.message ? errors.message : undefined}
      />
      <Button type="submit" isFullWidth disabled={isSubmitting}>
        Submit
      </Button>
    </form>
  </div>
);
export default withFormik({
  mapPropsToValues: () => ({
    email: '',
    telephone: '',
    name: '',
    message: '',
  }),
  validationSchema: yup.object().shape({
    name: yup.string().required('Full name is required!'),
    telephone: yup.string().required('Mobile number is required!'),
    email: yup
      .string()
      .email('Invalid email address')
      .required('Email is required!'),
    message: yup.string().required('Message is required!'),
  }),
  handleSubmit: async (values, { setSubmitting, props, resetForm }) => {
    await props.onSubmit(values);
    setSubmitting(false);
    resetForm();
  },
  displayName: 'ContactForm',
})(ContactForm);
